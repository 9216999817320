<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        isEditing
          ? `Editer une relation : ${relation.partner_view_name} / ${relation.brand_name}`
          : "Créer un nouvelle relation"
      }}
    </p>
    <v-card class="pa-4">
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="FormRelation">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="relation.partner_view_id"
                  :items="partnerViewList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Vue partenaire"
                  item-text="label"
                  item-value="id"
                  :rules="[dropdownSelected]"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="relation.brand_id"
                  :items="brandList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Marque"
                  item-text="name"
                  item-value="id"
                  :rules="[dropdownSelected]"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="relation.billing_id"
                  :items="billingList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Entité de facturation"
                  item-text="name"
                  item-value="id"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="relation.cashing_id"
                  :items="cashingList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Entité d'encaissement"
                  item-text="name"
                  item-value="id"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveRelation"
                >
                  Enregistrer</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="goToList"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";

import { required } from "@core/utils/validation";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";

export default {
  name: "RelationEdit",
  data() {
    return {
      required,
      dropdownSelected: (v) => {
        return !!v;
      },
      relation: {
        id: 0,
        partner_view_id: 0,
        partner_name: "",
        brand_id: 0,
        brand_name: "",
        billing_id: 0,
        cashing_id: 0,
      },
    };
  },
  async created() {
    await this.$store.dispatch("common/getPartnerViewList");
    await this.$store.dispatch("common/getSiteBrandList");
    this.$store.dispatch("admin/getBillingList");
    this.$store.dispatch("admin/getCashingList");

    if ("id" in this.$route.params && this.$route.params.id) {
      const { data } = await axios.get(
        `/partners/relation/${this.$route.params.id}`
      );
      this.relation = {
        ...data,
        partner_view_name: this.getPartnerViewName(data.partner_view_id),
        brand_name: this.getBrandName(data.brand_id),
      };
    }
  },
  methods: {
    getPartnerViewName(partner_view_id) {
      return (
        this.partnerViewList.find(
          (partnerView) => partnerView.id === partner_view_id
        )?.label ?? ""
      );
    },
    getBrandName(brand_id) {
      return this.brandList.find((brand) => brand.id === brand_id)?.name ?? "";
    },
    async saveRelation() {
      const isFormValid = this.$refs.FormRelation.validate();
      if (!isFormValid) {
        return;
      }

      const payload = {
        id: this.relation.id > 0 ? this.relation.id : null,
        partner_view_id: this.relation.partner_view_id,
        brand_id: this.relation.brand_id,
        billing_id:
          this.relation.billing_id > 0 ? this.relation.billing_id : null,
        cashing_id:
          this.relation.cashing_id > 0 ? this.relation.cashing_id : null,
      };

      if (this.isEditing) {
        await axios.put(`/partners/relation/${this.relation.id}`, payload);
      } else {
        await axios.post(`/partners/relation`, payload);
      }

      await this.$store.dispatch("app/setSnackBar", {
        show: true,
        text: `La relation "${this.relation.partner_view_name}"
        / "${this.relation.brand_name}" a été ${
          this.isEditing ? "modifiée" : "créée"
        }.`,
        color: "success",
        timeout: NOTIF_DISPLAY_DURATION,
      });

      setTimeout(() => this.goToList(), NOTIF_DISPLAY_DURATION);
    },
    goToList() {
      this.$router.push({ name: "admin-partner-relations" });
    },
  },
  computed: {
    isEditing() {
      return this.relation.id > 0;
    },
    partnerViewList() {
      return this.$store.getters["common/getPartnerViewList"];
    },
    brandList() {
      return this.$store.getters["common/getSiteBrandList"];
    },
    billingList() {
      return this.$store.getters["admin/getBillingList"];
    },
    cashingList() {
      return this.$store.getters["admin/getCashingList"];
    },
  },
  watch: {
    "relation.partner_view_id"() {
      this.relation.partner_view_name = this.getPartnerViewName(
        this.relation.partner_view_id
      );
    },
    "relation.brand_id"() {
      this.relation.brand_name = this.getBrandName(this.relation.brand_id);
    },
  },
};
</script>
